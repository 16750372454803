import React from 'react'
import Topbar from './topbar'

class Container extends React.Component {
  render() {
    return <Topbar history={this.props.history} />
  }
}

export default Container
