import React from 'react'
import Home from './home'

class Container extends React.Component {
  render() {
    return <Home history={this.props.history} />
  }
}

export default Container
