import React from 'react'
import '../index.scss'
import '../App.scss'
import { Switch, Route} from 'react-router-dom'
import withLayout from '../components/withLayout'
import NotFound from './404'
import Home from './home'
import Credits from './credits'
import Play from './play'
import Rules from './rules'

export default () => {
  return (
    <Switch>
      <Route path="/" component={withLayout(Home)} exact />
      <Route path="/play" component={withLayout(Play)} />
      <Route path="/credits" component={withLayout(Credits)} />
      <Route path="/rules" component={withLayout(Rules)} />
      <Route path="/404" component={withLayout(NotFound)} />
      <Route path="*" component={withLayout(NotFound)} />
    </Switch>
  )
}
