import React from 'react'
const Topbar = props => {
  const { history } = props
  return (
    <div>
      <img
        alt="Let's Conjugate!"
        className="title-image"
        src="/images/title.png"
        onClick={() => {
          history.push('/')
        }}
      />
    </div>
  )
}

export default Topbar
