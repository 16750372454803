import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './notfound.scss'

const NotFound = () => {
  return (
    <Container>
      <Row>
        <Col xs={12}>Not Found</Col>
      </Row>
    </Container>
  )
}

export default NotFound
