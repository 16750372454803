import React from 'react'
import Status from '../components/status'
import NotFound from '../components/notfound'

const notfound = props => {
  return (
    <Status code={404}>
      <NotFound />
    </Status>
  )
}

export default notfound
